import { type CurrentUser } from "@wpf-ui/gql/generated-gateway/graphql";
import { type NextRouter } from "next/router";

interface PathPermission {
  requiredPermissions: string[];
}

const pathPermissionsMap = new Map<string, PathPermission>([
  ["/projects/[projectId]", { requiredPermissions: ["*"] }],
  [
    "/projects/[projectId]/entry",
    { requiredPermissions: ["isActiveUser", "isGeneralStaffUser", "isWorksStaffUser", "isWorksAdminUser"] },
  ],
  [
    "/projects/[projectId]/entries",
    {
      requiredPermissions: [
        "isActiveUser",
        "isGeneralStaffUser",
        "isWorksStaffUser",
        "isWorksAdminUser",
        "isWorksClientUser",
        "isWorksShemateUser",
      ],
    },
  ],
  [
    "/projects/[projectId]/entry-works/[entryWorkId]/resubmit",
    {
      requiredPermissions: [
        "isActiveUser",
        "isGeneralStaffUser",
        "isWorksStaffUser",
        "isWorksAdminUser",
        "isWorksClientUser",
        "isWorksShemateUser",
      ],
    },
  ],
  ["/auth", { requiredPermissions: [] }],
  ["/set_token", { requiredPermissions: [] }],
  [
    "/projects/[projectId]/rd",
    {
      requiredPermissions: ["*"],
    },
  ],
  [
    "/jobs",
    {
      requiredPermissions: [
        "isActiveUser",
        "isGeneralStaffUser",
        "isWorksStaffUser",
        "isWorksAdminUser",
        "isWorksClientUser",
        "isWorksShemateUser",
      ],
    },
  ],
  [
    "/jobs/[jobId]",
    {
      requiredPermissions: [
        "isActiveUser",
        "isGeneralStaffUser",
        "isWorksStaffUser",
        "isWorksAdminUser",
        "isWorksClientUser",
        "isWorksShemateUser",
      ],
    },
  ],
]);

const authPaths: string[] = ["/auth", "/set_token"];

export const isAllowedPathForCurrentUser = (router: NextRouter, currentUser: CurrentUser | null): boolean => {
  const currentPath = router.pathname;

  const isAuthPath = authPaths.includes(currentPath);
  if (isAuthPath) {
    return true;
  }

  const pathPermission = pathPermissionsMap.get(currentPath);

  if (!pathPermission) {
    return false; // Path not found in permissions
  }

  const hasRequiredPermission = pathPermission.requiredPermissions.some(
    (permission) => permission === "*" || currentUser?.[permission as keyof CurrentUser], // Access property by key
  );

  return hasRequiredPermission;
};

import { Flex, HStack, Image, Spacer } from "@chakra-ui/react";
import { type VFC } from "react";
import { IconImage } from "@wpf-ui/components/essentials/icon";
import { Typography } from "@wpf-ui/components/essentials/typography";

export interface HeaderProps {
  readonly profilePhotoUrl: string;
  readonly name: string;
}

export const Header: VFC<HeaderProps> = (props) => {
  const { profilePhotoUrl, name } = props;
  const iconFallbackSrc = "/images/default-user-profile.png";
  return (
    <Flex bg="white" position="sticky" top="0" zIndex="sticky" m="0" py="1rem" px="1.5rem">
      <Image src="/images/logo.svg" alt="logo" />
      <Spacer />
      <HStack>
        <IconImage
          src={profilePhotoUrl}
          fallbackSrc={iconFallbackSrc}
          boxSize={{ sp: "40px", pc: "32px" }}
          marginBottom="0px"
          boxShadow="0px"
        />
        <Typography variant="body3" display={{ sp: "none", pc: "block" }}>
          {name}
        </Typography>
      </HStack>
    </Flex>
  );
};

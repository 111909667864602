import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const wpfEnv = (): string => {
  if (publicRuntimeConfig === undefined) {
    return "development";
  }
  return publicRuntimeConfig.APP_ENV === undefined ? "development" : (publicRuntimeConfig.APP_ENV as string);
};

export const isStaging = (): boolean => {
  return publicRuntimeConfig.APP_ENV === "staging";
};

export const isDevelopment = (): boolean => {
  return publicRuntimeConfig.APP_ENV === undefined;
};

export const isProduction = (): boolean => {
  return publicRuntimeConfig.APP_ENV === "production";
};

import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { type Client, createClient, cacheExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import getConfig from "next/config";
import { createAuthConfig } from "@wpf-ui/utils/auth";
import { isDevelopment } from "@wpf-ui/utils/env";
import { persistedFetchExchange } from "@urql/exchange-persisted-fetch";
import persistedQueriesJson from "@wpf-ui/gql/generated-gateway/persisted.json";

const { publicRuntimeConfig } = getConfig();

export const createGraphqlGatewayClient = (token: string | null): Client => {
  return createClient({
    url: publicRuntimeConfig?.GRAPHQL_GATEWAY_URL || "http://localhost:4100/graphql",
    exchanges: [
      cacheExchange,
      authExchange(createAuthConfig(token)),
      ...(!isDevelopment()
        ? [
            persistedFetchExchange({
              // TODO: use preferGetForPersistedQueries after confirming the persisted operation works
              // preferGetForPersistedQueries: true,
              enforcePersistedQueries: true,
              enableForMutation: true,
              generateHash: async (_, document) => {
                const queryName = (
                  document.definitions[0] as unknown as { name: { value: keyof typeof persistedQueriesJson } }
                ).name.value;
                return persistedQueriesJson[queryName];
              },
            }),
          ]
        : []),
      multipartFetchExchange,
    ],
  });
};

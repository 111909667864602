import { type CurrentUser } from "@wpf-ui/gql/generated-gateway/graphql";
import { useRouter } from "next/router";
import { useEffect } from "react";

export function getAuthPageUrl(): string {
  const query = new URLSearchParams({ redirectUrl: location.href }).toString();
  return `/auth?${query}`;
}

export function useRequestSignIn(currentUser: CurrentUser | null, isForbidden: boolean): void {
  const router = useRouter();
  useEffect(() => {
    if (router.pathname === "/projects/[projectId]") {
      // 募集閲覧ページはログインなしでも参照可能
      return;
    }

    if (
      !currentUser &&
      !isForbidden &&
      !(router.pathname.startsWith("/auth") || router.pathname.startsWith("/set_token"))
    ) {
      void router.push(getAuthPageUrl());
    }
  }, [router, currentUser, isForbidden]);
}

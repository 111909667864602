import { type CurrentUser as GraphQLCurrentUser } from "@wpf-ui/gql/generated-gateway/graphql";
import { createContext, useContext } from "react";

export type CurrentUser = GraphQLCurrentUser & {
  enabledFeatures: Set<string>;
};

export const CurrentUserContext = createContext<CurrentUser | null>(null);

export const useCurrentUser = (): CurrentUser | null => {
  return useContext(CurrentUserContext);
};

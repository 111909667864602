import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { type Client, createClient, dedupExchange, cacheExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import getConfig from "next/config";
import { createAuthConfig } from "@wpf-ui/utils/auth";

const { publicRuntimeConfig } = getConfig();

export const createGraphqlClient = (token: string | null): Client => {
  return createClient({
    url: publicRuntimeConfig?.GRAPHQL_URL || "http://localhost:4010/graphql",
    exchanges: [dedupExchange, cacheExchange, authExchange(createAuthConfig(token)), multipartFetchExchange],
  });
};

import { type AuthConfig } from "@urql/exchange-auth";
import { fetchToken, getSignInPageUrl } from "@wpf-ui/pages/auth/__hooks__";
import { responseContainsAuthNError } from "@wpf-ui/utils/errors";
import jwtDecode from "jwt-decode";
import { makeOperation } from "urql";

interface AuthState {
  token: string;
  // token expiration. decoded from `token` and kept inside state
  // in order not to decode it on every request
  exp: number;
}

interface DecodedJwt {
  exp: number;
}

// The following logic is basically copied from the docs
// https://formidable.com/open-source/urql/docs/advanced/authentication
export const createAuthConfig = (initialToken: string | null): AuthConfig<AuthState> => {
  return {
    addAuthToOperation: ({ authState, operation }) => {
      if (!authState?.token) {
        // when there is no AuthState, execute the operation as is.
        return operation;
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === "function"
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${authState.token}`,
          },
        },
      });
    },
    getAuth: async ({ authState }) => {
      if (!authState) {
        if (initialToken) {
          // use the initial token when the auth state doesn't exist.
          // (which basically means this is the first time `getAuth` is called)
          try {
            const { exp } = jwtDecode<DecodedJwt>(initialToken);
            const now = Math.floor(Date.now() / 1000);
            return exp < now ? await fetchNewToken() : { token: initialToken, exp };
          } catch (err: unknown) {
            return null;
          }
        }
        return null;
      }

      try {
        // When no valid token exists, try and fetch one
        return await fetchNewToken();
      } catch (err: unknown) {
        // This is where auth has gone wrong and we need to clean up and redirect to a login page
        location.href = getSignInPageUrl();
        return null;
      }
    },
    willAuthError: ({ authState }) => {
      const now = Math.floor(Date.now() / 1000);
      if (!authState || authState.exp < now) {
        // intentionally error out when token has expired.
        // this will fire `getAuth`
        return true;
      }
      return false;
    },
    didAuthError: ({ error }) => {
      return responseContainsAuthNError({ error });
    },
  };
};

async function fetchNewToken(): Promise<AuthState> {
  const token = await fetchToken();
  const { exp } = jwtDecode<DecodedJwt>(token);
  return { token, exp };
}

import { type ComponentStyleConfig } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

export const Button: ComponentStyleConfig = {
  variants: {
    primary: {
      bg: token.color.button.primary.bgDefault.value,
      variant: "solid",
      color: token.color.text.white.value,
      borderRadius: "112px",
      _hover: {
        _disabled: {
          bg: token.color.bg.gray.value,
          opacity: 1,
        },
      },
      _disabled: {
        bg: token.color.bg.gray.value,
        color: token.color.button.primary.textDisabled.value,
        opacity: 1,
      },
    },
    secondary: {
      bg: token.color.bg.gray.value,
      variant: "outline",
      color: token.color.button.secondary.textDefault.value,
      borderRadius: "112px",
    },
    outline: {
      bg: "transparent",
      color: token.color.text.primary.value,
      borderColor: token.color.button.primary.bgDefault.value,
      borderWidth: "1px",
      borderRadius: "112px",
      _hover: {
        bg: "white",
      },
    },
  },

  defaultProps: {
    variant: "primary",
  },
};

import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _invalid: {
        borderColor: "inherit",
      },
    },
  },

  defaultProps: {
    variant: "primary",
  },
};

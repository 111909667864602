import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { type NextParsedUrlQuery } from "next/dist/server/request-meta";
import dayjs from "dayjs";

const { publicRuntimeConfig } = getConfig();

const ACCOUNT_PAGE_URL = publicRuntimeConfig.ACCOUNT_PAGE_URL || "http://localhost:8090/account";
const COMMON_API_URL = publicRuntimeConfig.COMMON_API_URL || "http://localhost:8090/";

function urlJoin(url: string, ...paths: string[]): string {
  return (url.endsWith("/") ? url : url + "/") + paths.join("/");
}

export async function fetchToken(): Promise<string> {
  const search = new URLSearchParams({ exp: dayjs().add(15, "minute").toISOString() });
  const url = urlJoin(COMMON_API_URL as string, "api/v3/users/current/jwt", `?${search.toString()}`);
  const response = (
    await axios.get(url, {
      withCredentials: true,
    })
  ).data;
  if ("success" in response) {
    return response.body as string;
  } else {
    throw new Error(`Failed getting token: ${response.code as string}`);
  }
}

function getDestinationUrl(query: NextParsedUrlQuery): string {
  if (typeof query.redirectUrl === "string") {
    return query.redirectUrl;
  } else {
    return "/";
  }
}

function getSetTokenPageUrl(query: NextParsedUrlQuery, token: string): string {
  const redirectUrl = getDestinationUrl(query);
  const search = new URLSearchParams({ redirectUrl, token }).toString();
  return urlJoin("/set_token", `?${search}`);
}

export function getSignInPageUrl(): string {
  const params = new URLSearchParams({ redirect_url: location.href }).toString();
  return urlJoin(ACCOUNT_PAGE_URL as string, "sign_in", `?${params}`);
}

interface AuthState {
  fetching: boolean;
}

export function useTokenCheck(): AuthState {
  const [fetching, setFetching] = useState(true);
  const router = useRouter();
  const retrieveToken = useCallback(async () => {
    try {
      const token = await fetchToken();
      location.href = getSetTokenPageUrl(router.query, token);
    } catch {
      location.href = getSignInPageUrl();
    } finally {
      setFetching(false);
    }
  }, [router, setFetching]);
  useEffect(() => {
    void retrieveToken();
  }, [retrieveToken]);
  return { fetching };
}

import { type ReactNode, type VFC } from "react";
import { type TextProps, Text } from "@chakra-ui/react";

export interface TypographyProps extends TextProps {
  readonly children: ReactNode;
}

export const Typography: VFC<TypographyProps> = ({ children, ...props }) => {
  return <Text {...props}>{children}</Text>;
};

import { extendTheme } from "@chakra-ui/react";
import { fonts } from "./fonts";
import { Badge, Button, Text, Checkbox, Radio } from "@wpf-ui/styles/themes/components";

// sp = 0px ~ 544px
// tablet = 545px ~ 1011px
// pc = 1012px ~
export const breakpoints = {
  sm: "",
  md: "",
  lg: "",
  xl: "",
  "2xl": "",
  sp: "0px",
  tablet: "545px",
  pc: "1012px",
};

export const theme = extendTheme({
  fonts,
  breakpoints,
  components: {
    Badge,
    Button,
    Text,
    Checkbox,
    Radio,
  },
});

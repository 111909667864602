import { type VFC } from "react";
import { Image, type ImageProps } from "@chakra-ui/react";

export const IconImage: VFC<ImageProps> = ({
  marginBottom = "19px",
  boxShadow = "2px 2px 12px rgba(0, 0, 0, 0.08)",
  ...rest
}) => {
  return (
    <Image
      objectFit="cover"
      mb={marginBottom}
      borderRadius="50%"
      boxShadow={boxShadow}
      alt="profile_image"
      bgColor="white"
      {...rest}
    />
  );
};

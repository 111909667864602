import { type ComponentStyleConfig } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        bg: token.color.function.primaryDefault.value,
        borderColor: token.color.function.primaryDefault.value,
        _hover: {
          bg: token.color.function.primaryDefault.value,
          borderColor: token.color.function.primaryDefault.value,
        },
      },
      _invalid: {
        borderColor: "inherit",
      },
    },
  },

  variants: {
    sheDefault: () => {
      return {
        control: {
          borderWidth: "1px",
          borderColor: token.color.object.gray3.value,
          _checked: {
            bg: token.color.primitive.sheblue600.value,
            borderColor: "transparent",
            borderRadius: "3px",
          },
        },
        label: {
          ml: "6px",
        },
      };
    },
  },

  defaultProps: {
    variant: "primary",
  },
};

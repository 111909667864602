import { type FC, type VFC } from "react";
import { Box } from "@chakra-ui/react";
import { Header } from "@wpf-ui/components/fragments/header";
import { useCurrentUser } from "@wpf-ui/contexts";
interface DefaultLayoutProps {
  readonly children: React.ReactNode;
  bgColor?: string;
  mainWidth?: string;
  mainPadding?: string;
}

export const DefaultLayout: FC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  const { children, bgColor, mainWidth, mainPadding } = props;
  const currentUser = useCurrentUser();
  return (
    <Box bg={bgColor} h="100%" minHeight="100vh">
      {currentUser && <Header name={currentUser.name} profilePhotoUrl={currentUser.profilePhotoUrl} />}
      <Box as="main" w={{ pc: mainWidth ?? "90%", sp: "100%" }} m="0 auto" px={{ sp: mainPadding ?? "1.5rem" }}>
        {children}
      </Box>
    </Box>
  );
};
